<template>
  <Chat/>
</template>

<script>
import Chat from './views/Chat.vue'
export default {
  name: 'App',
  components: {
    Chat
  }
}
</script>

<style>
  #app {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: CustomFont;
    text-align: center;
    background: linear-gradient(to bottom right, #ffebd5, #d5f7ff);
  }
  /* background-image: url('@/assets/jpgs/bg-chatti.jpg'); */
  @font-face {
    font-family: 'CustomFont';
    src: url('@/assets/fonts/WorkSans-Regular.ttf') format('truetype');
    font-weight: 400; /* Medium */
  }

  @font-face {
    font-family: 'CustomFont';
    src: url('@/assets/fonts/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium */
  }

  @font-face {
    font-family: 'CustomFont';
    src: url('@/assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600; /* Semi-bold */
  }

  @font-face {
    font-family: 'CustomFont';
    src: url('@/assets/fonts/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
  }

  /* src/assets/styles/main.css */
  input, textarea {
    font-family: CustomFont; /* O la fuente que desees */
    font-weight: 400; /* Regular, por ejemplo */
  }

  /* Estilizar el placeholder específicamente */
  input::placeholder,
  textarea::placeholder {
    font-family: CustomFont; /* Asegúrate de usar la misma fuente */
    font-weight: 400; /* Color personalizado para el placeholder */
  }
</style>
