<template>
  <div
    class="container-msg"
    :class="{ 'ia-class': type === 'ia', 'user-class': type === 'user' }">
    <img v-if="type === 'ia'" class="icon-chat"
      :src="require('@/assets/svgs/icon_chat.svg')" alt="Icono" />
    <p
      v-html="formattedMsg"
      :class="{ 'txt-msg-user': type === 'user', 'txt-msg-ia': type === 'ia' }"></p>
    <img v-if="type === 'user'" class="icon-chat"
      :src="require('@/assets/svgs/icon_user.svg')" alt="Icono" />
  </div>
</template>

<script>
export default {
  name: 'IAMsg',
  props: {
    msg: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedMsg () {
      return this.parseMarkdown(this.msg)
    }
  },
  methods: {
    parseMarkdown (markdown) {
      let html = markdown
      // Reemplazar **texto** por <strong>texto</strong> (negrita)
      html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      // Reemplazar *texto* por <em>texto</em> (cursiva)
      html = html.replace(/\*(.*?)\*/g, '<em>$1</em>')
      // Reemplazar [texto](url) por <a href="url">texto</a> (enlaces)
      html = html.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>')
      // Reemplazar `codigo` por <code>codigo</code> (código en línea)
      html = html.replace(/`(.*?)`/g, '<code>$1</code>')
      // Reemplazar saltos de línea con <br>
      html = html.replace(/\n/g, '<br>')
      return html
    }
  }
}
</script>

<style scoped>
.container-msg {
  margin: 5px;
  display: flex;
  color: white;
  align-items: center;
}
.ia-class {
  flex-direction: row;
}
.user-class {
  flex-direction: row-reverse;
}
.txt-msg-user {
  background: #2b8be2;
  color: #ffffff;
  padding: 10px;
  margin-right: 5px;
  border-radius: 8px;
  text-align: right;
}
.txt-msg-ia {
  background: #faf9e7;
  color: #0d0d0d;
  padding: 10px;
  margin-right: 5px;
  border-radius: 8px;
  text-align: left;
}
.icon-chat {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  background-color: white;
  border-radius: 50%;
  text-align: left;
}
</style>
