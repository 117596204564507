<template>
  <div class="chat-container">
    <img class="img_logo"
      src='@/assets/svgs/logo.svg' alt="Logo">
    <div v-if="msgs.length === 0"
      class="welcome-container" >
      <span class="txt_big" style="margin-bottom: 5px;">¡Hola!</span>
      <span class="txt_small">¿En qué puedo ayudarte?</span>
    </div>
    <div v-else
      class="conversation-container" >
      <Msg  v-for="(item, index) in msgs.slice().reverse()"
        :key="index" :msg="item.msg"
        :type="item.type"/>
    </div>
    <Load v-if="loading" />
    <div class="edit-txt-container" >
      <textarea class="input-question"
        type="text" id="question"
        @keyup.enter="handleEnter"
        v-model="question" placeholder="Envía tu mensaje…" />
      <button class="send-button" @click="addMsg">
        <img class="button-img"
          :src="question === '' || loading ? require('@/assets/svgs/icon_button_disable.svg') :
          require('@/assets/svgs/icon_button.svg')" alt="Icono" />
      </button>
    </div>
    <span class="footer_text" >
      © Chatti. 2024 Todos los derechos reservados.   |
      <a  class="link-chat" href="https://chatti.work/politica-de-privacidad.html" target="_blank"> Política de Privacidad </a> •
      <a class="link-chat" href="https://chatti.work/terminos-del-servicio.html" target="_blank"> Términos del Servicio </a>
    </span>
  </div>
</template>

<script>
import Load from '@/components/LoadChat.vue'
import Msg from '@/components/Msg.vue'
import axios from 'axios'
export default {
  name: 'IAChat',
  components: {
    Msg,
    Load
  },
  data () {
    return {
      question: '',
      msgs: [],
      loading: false,
      cont: 0
    }
  },
  mounted () {
    document.title = 'Chatti | Convierte dudas en ventas'
  },
  methods: {
    addMsg () {
      if (this.question !== '' && this.loading === false) {
        this.loading = true
        this.threadRun()
        // msg user
        this.msgs.push(
          {
            msg: this.question,
            type: 'user'
          })
        this.question = ''
      }
    },
    handleEnter () {
      this.addMsg() // Llama a la función cuando se presiona Enter
    },
    threadRun () {
      axios.post('https://api.openai.com/v1/threads/runs', {
        assistant_id: process.env.VUE_APP_ASSISTANT_ID,
        thread: {
          messages: [
            { role: 'user', content: this.question }
          ]
        }
      })
        .then(response => {
          this.retriveRun(response.data.id, response.data.thread_id)
        })
        .catch(_ => {
          this.errorMsg()
        })
    },
    retriveRun (idRun, idThread) {
      axios.get('https://api.openai.com/v1/threads/' + idThread + '/runs/' + idRun)
        .then(response => {
          if (response.data.status !== 'completed') {
            this.cont += 1
            if (this.cont < 10) {
              setTimeout(() => {
                this.retriveRun(idRun, idThread)
              }, 1000)
            } else {
              this.errorMsg()
            }
          } else {
            this.getMesage(idThread)
          }
        })
        .catch(_ => {
          this.errorMsg()
        })
    },
    getMesage (idThread) {
      axios.get('https://api.openai.com/v1/threads/' + idThread + '/messages')
        .then(response => {
          const res = response.data.data[0].content
          this.msgs.push(
            {
              msg: res[0].text.value,
              type: 'ia'
            })
          this.loading = false
        })
        .catch(_ => {
          this.errorMsg()
        })
    },
    errorMsg () {
      this.msgs.push(
        {
          msg: 'Tengo problemas para comunicarme intentalo nuevamente',
          type: 'ia'
        })
      this.loading = false
    }

  }
}
</script>

<style scoped>
  @import './../assets/styles/chat.css';
</style>
